import Image from './Image';
import Grid from '@mui/material/Grid';

const ShowImage = ({ images }) => {
    const show = (image) => {
        return <Image image={image} />
    };

    return <Grid container spacing={2}>{images.map(show)}</Grid>;
};

export default ShowImage;