import React, { useMemo, useState, useEffect } from 'react';
import { db, dbchat } from '../firebase';
import { getAuth } from "firebase/auth";
import Snackbar from '@mui/material/Snackbar';
import { Box, Card, Container, Paper, Stack, Switch, Typography, useTheme } from "@mui/material";
import Slide from '@mui/material/Slide';
import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import IconButton from '@mui/material/IconButton';
import { fromFirebase, formatDate, copyToPermanent } from "../components/Utils";
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import Flag from '@mui/icons-material/Flag';
import _ from "lodash";
import { collection, doc, getDocs, query, setDoc, onSnapshot, where, orderBy, and } from 'firebase/firestore';
import { DateTime } from 'luxon';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import util from 'util';
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'

const PersonaRequestCardV3 = (props) => {
    var imgRef = React.createRef(null);
    var [username, setUsername] = useState(null);
    var [isNSFW, setNSFW] = useState(false);
    var [containsIP, setContainsIP] = useState(false);
    var [tags, setTags] = useState([]);
    const allTags = [
        "PG", "PG-13", "R"
    ];

    const handleRatingTagsChange = (event) => {
        const {
          target: { value },
        } = event;
        setTags(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
    /**
     * id: str
        name: str
        prompt: str
        negative_prompt: str
        model: Optional[str] = None
        is_public: bool
        image: Optional[str] = None
        description: str
        voice: Optional[str] = None
     */

    const [notificationSettings, setNotificationSettings] = useState({
        open: false,
        message: ""
    });
    
    useEffect(() => {
        // const lookupUserById = async (userId) => {
        //     const q = query(collection(db, 'users'), where('id', '==', userId));

        //     const snapshot = await getDocs(q);

        //     if (snapshot.size === 1) {
        //         const user = snapshot.docs[0].data()
        //         setUsername(user.profile.username ?? "[Anonymous]");
        //     }
        // }

        // lookupUserById(props.request.submittedBy);

        // setNSFW(props.request.meta.isNSFW)
        // setMature(props.request.meta.isNSFW);
    }, []);

    async function approveRequest(request) {

        console.log(`Request ${util.inspect(request)}`);

        // request['workflow']['meta']['isNSFW'] = isNSFW;
        // request['workflow']['containsIP'] = containsIP ? true : false;
        request['reviewedAt'] = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
        request['reviewedBy'] = getAuth().currentUser.uid;
        request['is_public'] = true;
        request['status'] = 'pre-approved';
        var baseTags = request["tags"] ? request["tags"] : [];
        request["tags"] = [...baseTags, ...tags]

        // const uid = request["submittedBy"];

        // const newUrl = await copyToPermanent(uid, request['workflow'], "FeedImages");
        // request["workflow"]["meta"]["imageURL"] = newUrl;

        await setDoc(doc(dbchat, "published_personas", request.id), request);
        await setDoc(doc(dbchat, "public_personas", request.id), request);
        setNotificationSettings({open: true, message: "request approved"});
        setTimeout(() => setNotificationSettings({open: false, message: ""}), 5000)
    }

    function rejectRequest(request) {
        request['reviewedAt'] = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
        request['reviewedBy'] = getAuth().currentUser.uid;
        request['status'] = 'rejected';

        setDoc(doc(dbchat, "published_personas", request.id), request);
        setNotificationSettings({open: true, message: "request rejected"});
        setTimeout(() => setNotificationSettings({open: false, message: ""}), 5000)
    }

    function flagRequest(request) {
        request['reviewedAt'] = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
        request['reviewedBy'] = getAuth().currentUser.uid;
        request['status'] = 'flagged';

        setDoc(doc(dbchat, "published_personas", request.id), request);
        setNotificationSettings({open: true, message: "request flagged"});
        setTimeout(() => setNotificationSettings({open: false, message: ""}), 5000)
    }

    const didChangeNSFW = (event) => {
        setNSFW(event.target.checked)
    };

    const didChangeIPSetting = (event) => {
        setContainsIP(event.target.checked)
    };

    // const onLoad = (element) => {
    //     setDimensions(`${util.inspect(imgRef.current.naturalWidth)} x ${util.inspect(imgRef.current.naturalHeight)}`);
    // }

    return (
        <Card variant="outlined">
            <Snackbar
                open={notificationSettings.open}
                autoHideDuration={5000}
                message={notificationSettings.message}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
            <Stack
                // height={750}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={0}
            >
                <img
                    className='hero'
                    ref={imgRef}
                    src={_.get(props, "request.image")}
                    border={true}
                    zoom={false}
                    // onLoad={onLoad}
                    alt={"error"}
                />
                <CardContent sx={{ width: "50%" }}>

                    
                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">ID:</Typography>
                    <Typography display="inline" variant="body1">{props.request.id}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">User ID:</Typography>
                    <Typography display="inline" variant="body1">{props.request.owner_id}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Name:</Typography>
                    <Typography display="inline" variant="body1">{props.request.name}</Typography><br/>

                    {/* <Typography sx={{ mr: 1 }} display="inline" variant="h6">Submitted At:</Typography>
                    <Typography display="inline" variant="body1">{formatDate(fromFirebase(props.request.submittedAt))}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Submitted By:</Typography>
                    <Typography display="inline" variant="body1">{username}</Typography><br/> */}

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Model:</Typography>
                    <Typography display="inline" variant="body1">{props.request.model}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Description:</Typography>
                    <Typography sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "5",
                            WebkitBoxOrient: "vertical",
                        }} variant="body1">{props.request.description}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Voice:</Typography>
                    <Typography display="inline" variant="body1">{props.request.voice}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Use Narrator:</Typography>
                    <Switch checked={props.request.use_narrator} name='use-narrator' /><br/><br/>
                    
                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Rating Tags:</Typography>
                    <Select
                        labelId="rating-tags"
                        id="rating-tags"
                        multiple
                        value={tags}
                        onChange={handleRatingTagsChange}
                        MenuProps={{PaperProps: {
                            style: {
                                maxHeight: 48 * 4.5 + 8,
                                width: 250,
                            },
                          }}}
                    >
                        {allTags.map((tag) => (
                            <MenuItem
                                key={tag}
                                value={tag}
                            >
                            {tag}
                            </MenuItem>
                        ))}
                    </Select>

                    {/* <Typography sx={{ mr: 1 }} display="inline" variant="h6">NSFW:</Typography>
                    <Switch checked={isNSFW} name='isNSFW' onClick={didChangeNSFW} /><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Contains IP:</Typography>
                    <Switch checked={containsIP} name='containsIP' onClick={didChangeIPSetting} /><br/><br/> */}

                    <Typography variant="h6">Prompt:</Typography>
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "5",
                            WebkitBoxOrient: "vertical",
                        }}
                        variant="body1">{props.request.prompt}</Typography><br/>

                    <Typography variant="h6">Negative Prompt:</Typography>
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "5",
                            WebkitBoxOrient: "vertical",
                        }}
                        variant="body1">{props.request.negative_prompt}</Typography><br/>

                    <CardActions
                        disableSpacing
                        sx={{
                            alignSelf: "stretch",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-start",
                            p: 0,
                        }}>
                        <IconButton onClick={() => { approveRequest(props.request) }} aria-label="approve">
                            <ThumbUp />
                        </IconButton>
                        <IconButton onClick={() => { rejectRequest(props.request) }} aria-label="reject">
                            <ThumbDown />
                        </IconButton>
                        <IconButton onClick={() => { flagRequest(props.request) }} aria-label="flag">
                            <Flag />
                        </IconButton>
                    </CardActions>
                </CardContent>
            </Stack>
        </Card>
    )
}

const RequestStack = (props) => {
    const rows = props.requests.map((request) => (
        <PersonaRequestCardV3 key={request.id} request={request} />
    ));

    return (
        <Stack spacing={5}>
            {rows}
        </Stack>
    )
}

export const PersonaFeedRequestGrid = () => {
    const [requests, setRequests] = useState([]);
    // {
    //     description: "You are the monster cthulhu",
    //     id: "1AF24E33-5B6E-4079-BC48-E314ECABD528",
    //     image: "https://storage.googleapis.com/diffusitron-prod.appspot.com/Images/abc125_7b93f073a1aac3a0/img_d86a76ae-1d60-42ed-974a-626041d22783.jpg",
    //     is_public: true,
    //     likes: 0,
    //     model: "chimera-mi-xl",
    //     name: "Cthulhu",
    //     negative_prompt: "Cartoon rendering",
    //     prompt: "Polaroid vintage photo of cthulhu monster under the deep sea, menacing, realistic",
    //     use_narrator: true,
    //     voice: "N2lVS1w4EtoT3dr4eOWO"
    // }
    const [refresh, setRefresh] = useState(false);

    // const getAllPersonas = async () => {
    //     const q = query(collection(dbchat, "public_personas"));
    //     console.log("trying to get personas")
    //     const currDocs = await getDocs(q);
    //     let personas = [];
    //     for (let currDoc of currDocs.docs) {
    //         const currUserDoc = currDoc.data();
    //         const userId =  currUserDoc.name;
    //         const currPersonas = currUserDoc.personas;
    //         for (let currp of currPersonas) {
    //             currp.userid = userId;
    //             personas.push(currp);
    //         }
    //     }
    //     setRequests(personas);
    // }

    // useEffect(() => {
    //     getAllPersonas()
    // }, []);

    // console.log(`Current user is ${JSON.stringify(getAuth().currentUser)}`);

    useEffect(() => {
        const q = query(collection(dbchat, "published_personas"));

        const unsubscribe = onSnapshot(q,
            (snapshot) => {
                const source = snapshot.metadata.hasPendingWrites ? "local" : "server";
                console.log(`onSnapshot from ${source}`);
                console.log(`Number of items = ${snapshot.docs.length}`);
                var requests = [];
                for (let doc of snapshot.docs) {
                    if (!doc.data().status) {
                        requests.push({ id: doc.id, ...doc.data() });
                    }
                }
                // console.log(requests);
                setRequests(requests);
            },
            (error) => {
                console.log(`Error = ${error}`);
            }
        )
    }, []);

    return (
        // <NoDraggingLayout requests={requests} />
        <RequestStack requests={requests} />
    )
}

export const ModerationPersona = () => {
    const [ prod, setProd ] = React.useState(false);

    const theme = useTheme();

    // console.log('Current theme: ', theme)

    const [state, setState] = React.useState({
        open: false,
        Transition: Slide,
    });

    const handleClick = (Transition) => () => {
        setState({
            open: true,
            Transition,
        });
    };

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    return (
        <Container component="main" maxWidth="lg" sx={{ mb: 4, my: { xs: 3, md: 6 } }}>
            <PersonaFeedRequestGrid />
        </Container>
    )
};
