import * as React from 'react';
import { Container, useTheme } from '@mui/material';
import Slide from '@mui/material/Slide';
import RequestGrid from '../components/RequestGrid';

const Moderation = () => {
    const [ prod, setProd ] = React.useState(false);

    const theme = useTheme();

    // console.log('Current theme: ', theme)

    const [state, setState] = React.useState({
        open: false,
        Transition: Slide,
    });

    const handleClick = (Transition) => () => {
        setState({
            open: true,
            Transition,
        });
    };

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    return (
        <Container component="main" maxWidth="lg" sx={{ mb: 4, my: { xs: 3, md: 6 } }}>
            <RequestGrid />
        </Container>
    )
};

export default Moderation;