import * as React from 'react';
import Container from '@mui/material/Container';
import axios from 'axios';
import { Button } from '@mui/material';

import { collection, query, where, getDocs, orderBy, limit, startAfter, setDoc, deleteDoc, writeBatch, doc } from "firebase/firestore";
import { db } from '../firebase';
import { copyToPermanent } from '../components/Utils';

export default function Dashboard() {

    const moveApprovedImagesToPermant = async () => {
        var currentQuery = query(collection(db, "/feed"), where("status", "==", "approved"));
        var gallaryDocs = await getDocs(currentQuery);

        var batch = writeBatch(db);
        console.log(`Working on approved images, ${gallaryDocs.docs.length}`)
        let i = 0
        for (let currdoc of gallaryDocs.docs) {
            const docData = currdoc.data();
            const currDocRef = doc(db, "feed", docData.id);
            try {
                const uid = docData["submittedBy"];
                const newUrl = await copyToPermanent(uid, docData['workflow'], "FeedImages");
                if (newUrl === docData["workflow"]["meta"]["imageURL"]) {
                    continue;
                }
                docData["workflow"]["meta"]["imageURL"] = newUrl;
            } catch (e) {
                docData["status"] = "expired";
            }
            console.log(`${i} Updated.`)
            batch.set(currDocRef, docData);
        }
        batch.commit();

        currentQuery = query(collection(db, "/feed"), where("status", "==", "pre-approved"));
        gallaryDocs = await getDocs(currentQuery);
        
        batch = writeBatch(db);
        console.log(`Working on pre-approved images, ${gallaryDocs.docs.length}`)
        i = 0;
        for (let currdoc of gallaryDocs.docs) {
            const docData = currdoc.data();
            const currDocRef = doc(db, "feed", docData.id);
            try {
                const uid = docData["submittedBy"];
                const newUrl = await copyToPermanent(uid, docData['workflow'], "FeedImages");
                if (newUrl === docData["workflow"]["meta"]["imageURL"]) {
                    continue;
                }
                docData["workflow"]["meta"]["imageURL"] = newUrl;
            } catch (e) {
                docData["status"] = "expired";
            }
            console.log(`${i} Updated.`)
            batch.set(currDocRef, docData);
        }
        batch.commit();
    }

    const removeExpiredImages = async () => {
        var currentQuery = query(collection(db, "/feed"), where("status", "==", "expired"));
        var gallaryDocs = await getDocs(currentQuery);

        console.log(`expired size: ${gallaryDocs.docs.length}`)
        const batch = writeBatch(db);
        for (let currdoc of gallaryDocs.docs) {
            const docData = currdoc.data();
            if (docData["workflow"]["meta"]["imageURL"].includes("FeedImage")) {
                const currDocRef = doc(db, "feed", docData.id);
                docData["status"] = "approved";
                console.log("Updating a valid image: ");
                console.log(docData)
                batch.set(currDocRef, docData);
            }
        }
        await batch.commit();
        console.log("reset valid done");
    }

    return (
        <Container component="main" maxWidth="xs">
            <h1>Dashboard</h1>

            <Button onClick={moveApprovedImagesToPermant}>Move Approved Images to Permanent</Button>

            <Button onClick={removeExpiredImages}>Remove Expired Feeds</Button>
        </Container>
    );
}