import './App.css';
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Navigate, Routes, Route, useLocation } from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Moderation from './pages/Moderation';
import { ModerationPersona } from './pages/ModerationPersona';
import Users from './pages/Users';
import Flagged from './pages/Flagged';
import Loras from './pages/Loras';
import Models from './pages/Models';
import NotFound from './pages/NotFound';
import Runpod from './pages/Runpod';
import SignIn from './pages/SignIn';
import SignInChat from './pages/SignInChat';
import Layout from './pages/Layout';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const PrivateRoute = (props) => {
    const { children } = props
    const isLoggedIn = localStorage.getItem('logged_user') !== null;
    const location = useLocation()

    return isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to="/signin"
            state={{ from: `${location.pathname}${location.search}` }}
        />
    )
}

const PrivateRoute2 = (props) => {
    const { children } = props
    const isLoggedIn = localStorage.getItem('logged_user_chat') !== null;
    const location = useLocation()

    return isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to="/signin-chat"
            state={{ from: `${location.pathname}${location.search}` }}
        />
    )
}

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                components: {
                    MuiTypography: {
                        styleOverrides: {
                            body1: {
                                // color: 'rgba(255, 255, 255, 0.50)'
                            }
                        }
                    },
                    MuiGrid2: {
                        styleOverrides: {
                            root: {
                                backgroundColor: 'transparent'
                            },
                        },
                    },
                },
                // palette: {
                //     primary: {
                //         main: "rgb(255, 255, 255, 0.75)"
                //     },
                //     secondary: {
                //         main: "rgb(255, 255, 255, 0.25)"
                //     },
                //     mode: prefersDarkMode ? 'dark' : 'light',
                // },
            }),
        [prefersDarkMode],
    );

    return (
        // <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout />} >
                            <Route index element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                            <Route path="/moderation" element={<PrivateRoute><Moderation /></PrivateRoute>} />
                            <Route path="/moderation-persona" element={<PrivateRoute2><ModerationPersona /></PrivateRoute2>} />
                            <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
                            <Route path="/models" element={<PrivateRoute><Models /></PrivateRoute>} />
                            <Route path="/loras" element={<PrivateRoute><Loras /></PrivateRoute>} />
                            <Route path="/flagged" element={<PrivateRoute><Flagged /></PrivateRoute>} />
                            <Route path="/runpod" element={<PrivateRoute><Runpod /></PrivateRoute>} />
                        </Route>
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="/signin-chat" element={<SignInChat />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        // </ColorModeContext.Provider>
    );
}

export default App;
