import React, { useMemo, useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { db } from '../firebase';
import { deleteDoc, collection, doc, getDocs, query, setDoc, onSnapshot, orderBy, where } from 'firebase/firestore';
import { DateTime } from 'luxon';
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import RequestStack from './RequestStack';

const FeedRequestGrid = () => {
    const [requests, setRequests] = useState([]);
    const [refresh, setRefresh] = useState(false);

    // console.log(`Current user is ${JSON.stringify(getAuth().currentUser)}`);

    useEffect(() => {
        const q = query(collection(db, 'feed'), where('status', '==', 'pending'), orderBy('submittedAt', 'desc'));

        const unsubscribe = onSnapshot(q,
            (snapshot) => {
                const source = snapshot.metadata.hasPendingWrites ? "local" : "server";
                console.log(`onSnapshot from ${source}`);
                console.log(`Number of items = ${snapshot.docs.length}`);
                var requests = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                // console.log(requests);
                setRequests(requests);
            },
            (error) => {
                console.log(`Error = ${error}`);
            }
        )
    }, []);

    return (
        // <NoDraggingLayout requests={requests} />
        <RequestStack requests={requests} />
    )
}

const RequestGrid = () => (
    <FeedRequestGrid />
)

export default RequestGrid;