import * as React from 'react';
import { Outlet } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Copyright from '../components/Copyright';

export default function Dashboard() {
    const idx = Math.floor(Math.random() * 6);
    const filename = `/background${idx}.jpg`;

    const rotatingBackground = {
        backgroundImage: `url(${process.env.PUBLIC_URL + filename})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundSize: 'cover',
        filter: 'blur(12px) brightness(25%)'
    }

return (
    <React.Fragment>
        {/* <div style={rotatingBackground}></div> */}
        <div className="content">
            <ResponsiveAppBar />
        </div>
        <div className="content">
            <Outlet />
        </div>
        <Copyright sx={{ pt: 4 }} />
    </React.Fragment>
);
}