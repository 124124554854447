import React, { useEffect, useRef, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Box, Card, Paper, Stack, Switch, Typography } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import IconButton from '@mui/material/IconButton';
import { fromFirebase, formatDate, copyToPermanent } from "./Utils";
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import Flag from '@mui/icons-material/Flag';
import _ from "lodash";
import { db } from '../firebase';
import { getAuth } from "firebase/auth";
import { collection, doc, getDocs, query, setDoc, onSnapshot, where } from 'firebase/firestore';
import { DateTime } from 'luxon';
import util from 'util';

export default function RequestCardV3(props) {
    var imgRef = React.createRef(null);
    // var imgRef = useRef();
    var [loaded, setLoaded] = useState(false);
    var [dimensions, setDimensions] = useState(null);
    var [username, setUsername] = useState(null);
    var [isNSFW, setNSFW] = useState(false);
    var [isMature, setMature] = useState(false);
    var [containsIP, setContainsIP] = useState(false);

    const [notificationSettings, setNotificationSettings] = useState({
        open: false,
        message: ""
    });
    
    useEffect(() => {
        const lookupUserById = async (userId) => {
            const q = query(collection(db, 'users'), where('id', '==', userId));

            const snapshot = await getDocs(q);

            if (snapshot.size === 1) {
                const user = snapshot.docs[0].data()
                setUsername(user.profile.username ?? "[Anonymous]");
            }
        }

        lookupUserById(props.request.submittedBy);

        setNSFW(props.request.workflow.meta.isNSFW)
        setMature(props.request.workflow.meta.isNSFW);
    }, []);

    async function approveRequest(request) {

        console.log(`Request ${util.inspect(request)}`);

        request['workflow']['meta']['isNSFW'] = isNSFW;
        request['workflow']['containsIP'] = containsIP ? true : false;
        request['workflow']['imageWidth'] = imgRef.current.naturalWidth;
        request['workflow']['imageHeight'] = imgRef.current.naturalHeight;
        request['reviewedAt'] = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
        request['reviewedBy'] = getAuth().currentUser.uid;
        request['status'] = 'pre-approved';

        const uid = request["submittedBy"];

        const newUrl = await copyToPermanent(uid, request['workflow'], "FeedImages");
        request["workflow"]["meta"]["imageURL"] = newUrl;

        await setDoc(doc(db, "feed", request.id), request);
        setNotificationSettings({open: true, message: "request approved"});
        setTimeout(() => setNotificationSettings({open: false, message: ""}), 5000)
    }

    function rejectRequest(request) {
        request['workflow']['imageWidth'] = imgRef.current.naturalWidth;
        request['workflow']['imageHeight'] = imgRef.current.naturalHeight;
        request['reviewedAt'] = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
        request['reviewedBy'] = getAuth().currentUser.uid;
        request['status'] = 'rejected';

        setDoc(doc(db, "feed", request.id), request);
        setNotificationSettings({open: true, message: "request rejected"});
        setTimeout(() => setNotificationSettings({open: false, message: ""}), 5000)
    }

    function flagRequest(request) {
        request['workflow']['imageWidth'] = imgRef.current.naturalWidth;
        request['workflow']['imageHeight'] = imgRef.current.naturalHeight;
        request['reviewedAt'] = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
        request['reviewedBy'] = getAuth().currentUser.uid;
        request['status'] = 'flagged';

        setDoc(doc(db, "feed", request.id), request);
        setNotificationSettings({open: true, message: "request flagged"});
        setTimeout(() => setNotificationSettings({open: false, message: ""}), 5000)
    }

    const didChangeNSFW = (event) => {
        setNSFW(event.target.checked)
    };

    const didChangeIPSetting = (event) => {
        setContainsIP(event.target.checked)
    };

    const onLoad = (element) => {
        setDimensions(`${util.inspect(imgRef.current.naturalWidth)} x ${util.inspect(imgRef.current.naturalHeight)}`);
    }

    return (
        <Card variant="outlined">
            <Snackbar
                open={notificationSettings.open}
                autoHideDuration={5000}
                message={notificationSettings.message}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
            <Stack
                height={750}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={0}
            >
                <img
                    className='hero'
                    ref={imgRef}
                    src={_.get(props, "request.workflow.meta.imageURL")}
                    border={true}
                    zoom={false}
                    onLoad={onLoad}
                    alt={"error"}
                />

                <CardContent sx={{ width: "50%" }}>
                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">ID:</Typography>
                    <Typography display="inline" variant="body1">{props.request.id}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Submitted At:</Typography>
                    <Typography display="inline" variant="body1">{formatDate(fromFirebase(props.request.submittedAt))}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Submitted By:</Typography>
                    <Typography display="inline" variant="body1">{username}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Dimensions:</Typography>
                    <Typography display="inline" variant="body1">{dimensions}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Model:</Typography>
                    <Typography display="inline" variant="body1">{props.request.workflow.baseModelName}</Typography><br/>
                    
                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">LoRA:</Typography>
                    <Typography display="inline" variant="body1">{props.request.workflow.loraModelName !== '' ? props.request.workflow.loraModelName : '[none]'}</Typography><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">NSFW:</Typography>
                    <Switch checked={isNSFW} name='isNSFW' onClick={didChangeNSFW} /><br/>

                    <Typography sx={{ mr: 1 }} display="inline" variant="h6">Contains IP:</Typography>
                    <Switch checked={containsIP} name='containsIP' onClick={didChangeIPSetting} /><br/><br/>

                    <Typography variant="h6">Positive Prompt:</Typography>
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "5",
                            WebkitBoxOrient: "vertical",
                        }}
                        variant="body1">{props.request.workflow.positivePrompt}</Typography><br/>

                    <Typography variant="h6">Negative Prompt:</Typography>
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "5",
                            WebkitBoxOrient: "vertical",
                        }}
                        variant="body1">{props.request.workflow.negativePrompt}</Typography><br/>

                    <CardActions
                        disableSpacing
                        sx={{
                            alignSelf: "stretch",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-start",
                            p: 0,
                        }}>
                        <IconButton onClick={() => { approveRequest(props.request) }} aria-label="approve">
                            <ThumbUp />
                        </IconButton>
                        <IconButton onClick={() => { rejectRequest(props.request) }} aria-label="reject">
                            <ThumbDown />
                        </IconButton>
                        <IconButton onClick={() => { flagRequest(props.request) }} aria-label="flag">
                            <Flag />
                        </IconButton>
                    </CardActions>
                </CardContent>
            </Stack>
        </Card>
    )
}