import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import logo from '../images/diffie_happy.png';
import { getAuth } from 'firebase/auth';
import { Button } from '@mui/material';

function ResponsiveAppBar() {
    // const theme = useTheme();
    // const [mode, setMode] = React.useState('light');
    // const colorMode = React.useMemo(
    //     () => ({
    //         toggleColorMode: () => {
    //             setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    //             console.log(`Changed mode to ${mode}`);
    //         },
    //     }),
    //     [],
    // );

    // const [theme, toggleTheme] = useDarkMode();

    const [ prod, setProd ] = useState(false);

    // console.log('Current env: ', env)

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleChangeEnv = (event, c) => {
        console.log('click! ', event.target.checked)
        setProd(event.target.checked)
        // setAnchorElUser(null);
    };

    const signOut = () => {
        getAuth().signOut()
        navigate("/signin")
    }

    const pages = [
        { id: "1", label: 'Dashboard', link: '/dashboard' },
        { id: "2", label: 'Moderation', link: '/moderation' },
        { id: "3", label: 'Moderation Persona', link: '/moderation-persona' },
        { id: "4", label: 'Models', link: '/models' },
        { id: "5", label: 'LoRAs', link: '/loras' },
        { id: "6", label: 'Users', link: '/users' },
        { id: "7", label: 'Notifications', link: '/notifications' },
    ];

    const settings = [
        { id: "7", label: 'Profile' },
        { id: "8", label: 'Account' },
        { id: "9", label: 'Sign Out', handler: signOut },
    ];

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box component="img" src={logo} sx={{ display: { xs: 'none', md: 'flex', maxHeight: "30px" }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'Helvetica',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        DIFFIE CENTRAL
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                                    <NavLink to={page.link}>
                                        {page.label}
                                    </NavLink>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box component="img" src={logo} sx={{ display: { xs: 'flex', md: 'none', maxHeight: "30px" }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'Helvetica',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        DIFFIE CENTRAL
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <NavLink key={page.link} to={page.link}>
                                {page.label}
                            </NavLink>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Switch checked={prod} name='env' onClick={handleChangeEnv} />
                                }
                            />
                        </FormGroup>
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {/* <IconButton sx={{ ml: 1 }} color="inherit">
                            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton> */}
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="{getAuth().currentUser.name}" src={logo} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.id} onClick={handleCloseUserMenu}>
                                    <Button onClick={setting.handler}>{setting.label}</Button>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;