import * as React from 'react';
import { Outlet } from "react-router-dom";
import Container from '@mui/material/Container';

const Flagged = () => {
    return <Container component="main" maxWidth="xs">
            <h1>Flagged</h1>
            <Outlet />
        </Container>
};

export default Flagged;