import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import DropBox from '../components/DropBox';
import ShowImage from '../components/ShowImage';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import { Axios } from 'axios';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const Models = () => {
    const [description, setDescription] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [modelName, setModelName] = useState('');
    const [isActive, setActive] = useState(false);
    const [images, setImages] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.map((file, index) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImages((prevState) => [
                    ...prevState,
                    { id: index, src: e.target.result },
                ]);
            };
            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    const [imageSent, setImageSent] = useState([]);

    const handleFile = (e) => {
        setImageSent(e.target.files[0]);
    };

    const createModel = (event) => {
        event.preventDefault();
        console.log(`model name = ${modelName}`);

        const model = {
            'id': uuidv4(),
            'isActive': isActive,
            'modelName': modelName,
            'description': description,
            'displayName': displayName,
            'thumbnails': [
                'https://storage.googleapis.com/diffusitron-prod.appspot.com/Images/4OSxSO7Yi2YUdgnEXTKkLfybjZ52/img_9b7bc0b6-84fc-4391-a341-35783d4ef3d3.jpg'
            ]
        };

        setDoc(doc(db, "loras", model.id), model);
    };

    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper className="paper" variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <form onSubmit={createModel} >
                        <Typography variant="h6" gutterBottom>
                            Model Card
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="displayName"
                                    name="displayName"
                                    label="Display name"
                                    fullWidth
                                    variant="standard"
                                    onInput={e => setDisplayName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="modelName"
                                    name="modelName"
                                    label="Model name"
                                    fullWidth
                                    variant="standard"
                                    onInput={e => setModelName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="description"
                                    name="description"
                                    label="Description"
                                    fullWidth
                                    variant="standard"
                                    onInput={e => setDescription(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox color="primary" name="isActive" checked={isActive} />}
                                    label="Make model active now"
                                    onChange={e => setActive(e.target.checked)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DropBox onDrop={onDrop} /><p />
                                <ShowImage images={images} />
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                                <Box display="flex" justifyContent="center">
                                    <Button
                                        style={{ width: '50%', height: '50px' }}
                                        variant='contained'
                                        color='primary'
                                        type='submit'>submit</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </React.Fragment>
    );
};

export default Models;