import React from "react";
import Grid from '@mui/material/Grid';

const thumb = {
    display: 'inline-flex',
    borderRadius: 4,
    border: '0px solid #eaeaea',
    // marginBottom: 8,
    // marginRight: 8,
    overflow: 'hidden',
    padding: 0,
    boxSizing: 'border-box'
};

const img = {
    aspectRatio: 1,
    width: '100%',
    objectFit: 'cover'
};

function Image({ image }) {
    return (
        <Grid item xs={12} sm={4}>
            <span style={thumb} key={image.name}>
                <img
                    alt={image.name}
                    src={image.src}
                    style={img}
                />
            </span>
        </Grid>
    );
}

export default Image;