import { Stack } from "@mui/material";
import RequestViewCardV3 from "./RequestCardV3";

const RequestStack = (props) => {
    const rows = props.requests.map((request) => (
        <RequestViewCardV3 key={request.workflow.id} request={request} />
    ));

    return (
        <Stack spacing={5}>
            {rows}
        </Stack>
    )
}

export default RequestStack;