import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

export const firebaseDevConfig = {
    description: "Development",
    apiKey: "AIzaSyAZ0oXfjPwppFbsTLU2m3LrHRGfg9PpXFY",
    authDomain: "diffusitron-dev.firebaseapp.com",
    databaseURL: "https://diffusitron-dev-default-rtdb.firebaseio.com",
    projectId: "diffusitron-dev",
    storageBucket: "diffusitron-dev.appspot.com",
    messagingSenderId: "529934710841",
    appId: "1:529934710841:web:a9c264bc1b3dbf37f1b5e8",
    measurementId: "G-G1LVGK2F6D"
};

const firebaseProdConfig = {
    description: "Production",
    apiKey: "AIzaSyA_yNJGCcSv5IG9JXEESAipl-ELA_Ydfc8",
    authDomain: "diffusitron-prod.firebaseapp.com",
    databaseURL: "https://diffusitron-prod-default-rtdb.firebaseio.com",
    projectId: "diffusitron-prod",
    storageBucket: "diffusitron-prod.appspot.com",
    messagingSenderId: "26011306565",
    appId: "1:26011306565:web:e441cff19d2a8cc2ca712c",
    measurementId: "G-RT6LM40YNP"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseProdConfig);

export const auth = getAuth(firebase);
export const db = getFirestore(firebase);
export const bucketStorage = getStorage(firebase, 'gs://diffusitron-prod.appspot.com');

const chataffirebaseConfig = {
    apiKey: "AIzaSyCROykWLEd6iYgIN6udfopiIMkZ2sqf-_Q",
    authDomain: "chataf-76053.firebaseapp.com",
    projectId: "chataf-76053",
    storageBucket: "chataf-76053.appspot.com",
    messagingSenderId: "870004628650",
    appId: "1:870004628650:web:0b009b55598d176ffc44d3",
    measurementId: "G-0W9YKCXKMS"
};

export const firebasechat = initializeApp(chataffirebaseConfig, "chat");

export const authchat = getAuth(firebasechat);
export const dbchat = getFirestore(firebasechat);