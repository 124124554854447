import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const Container = styled.div`
flex: 1;
display: flex;
flex-direction: column;
align-items: center;
padding: 20px;
border-width: 2px;
border-radius: 2px;
border-color: rgba(255, 255, 255, 0.50);
border-style: dashed;
background-color: clear;
color: #bdbdbd;
outline: none;
transition: border .24s ease-in-out;
`;

function DropBox({ onDrop }) {
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isDragAccept,
        isFocused,
        isDragReject,
    } = useDropzone({
        accept: 'image/*',
        onDrop,
    });

    const lists = acceptedFiles.map((list) => (
        <li key={list.path}>
            {list.path} - {list.size} bytes
        </li>
    ));

    return (
        <>
            {' '}
            <section className="dropbox">
                <Container
                    className="dropbox"
                    {...getRootProps({ isDragAccept, isFocused, isDragReject })}
                >
                    <input {...getInputProps()} />
                    <p>drag preview images here</p>
                </Container>
            </section>
            {/* <aside>
                <p>{lists}</p>
            </aside> */}
        </>
    );
}

export default DropBox;
