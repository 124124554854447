import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { authchat, dbchat } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { collection, doc, getDocs, query, setDoc, onSnapshot, where } from 'firebase/firestore';
import Copyright from '../components/Copyright';
import util from 'util';

const SignInChat = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const provider = new GoogleAuthProvider();

    useEffect(() => {
        authchat.onAuthStateChanged(user => {
            setUser(user);
        })
    }, []);

    const getUserById = async (userId) => {
        console.log(userId);
        const q = query(collection(dbchat, 'users'), where('id', '==', userId));

        const snapshot = await getDocs(q);

        if (snapshot.size === 1) {
            const user = snapshot.docs[0].data()
            console.log(`User = ${util.inspect(user)}`);
            return user;
            // setUsername(user.profile.username ?? "[Anonymous]");
        }

        return null;
    }

    const handleGoogleSignIn = (event) => {
        signInWithPopup(authchat, provider)
            .then((userCredential) => {
                const firebaseUser = userCredential.user;
                // const credential = GoogleAuthProvider.credentialFromResult(result);
                // const token = credential.accessToken;
                // const user = result.user;
                console.log(`UID = ${firebaseUser.uid}`);
                // localStorage.setItem('logged_user', user);
                
                const user = getUserById(firebaseUser.uid);
                if (user) {
                    localStorage.setItem("logged_user_chat", JSON.stringify(firebaseUser));
                    navigate("/moderation-persona")
                }
            }).catch((error) => {
                console.log(`Error = ${error}`);
                const errorCode = error.code;
                const errorMessage = error.message;
                // const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        // console.log({
        //     email: data.get('email'),
        //     password: data.get('password'),
        // });

        // console.log(`Current user is ${getAuth().currentUser}`);

        const email = data.get('email');
        const password = data.get('password');

        signInWithEmailAndPassword(authchat, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                localStorage.setItem('logged_user_chat', user);
                navigate("/moderation-persona")
                // console.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                alert(`${errorMessage}`);
            });
    };

    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>

                    <Button className="button" onClick={handleGoogleSignIn}><i className="fab fa-google"></i>Sign in with google</Button>

                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}

export default SignInChat